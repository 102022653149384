/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ImageModal from "../components/imageModal";
import ReactMarkdown from "react-markdown";
import { Carousel } from "react-responsive-carousel";
import { Button, Modal, message, Image } from "antd";
import Progress from "../components/progress";
import api, { url } from "../api";
import moment from "moment";
import { AuthContext } from "../context/GlobalContextProvider";
import Timeline from "../components/timeLine";
import { navigate } from "gatsby";
import dream from "../images/dream-icon.png";
import club from "../images/club-icon.png";
import exclusivity from "../images/exclusivity-icon.png";
import { ZoomInOutlined, RightOutlined, LeftOutlined } from "@ant-design/icons";

export default function Predio({ location }) {
  const [predio, setPredio] = useState();
  const [loading, setLoading] = useState(true);
  const context = useContext(AuthContext);
  const getData = async (id) => {
    const response = await api.get(`predios/${id}`);
    setPredio(response.data);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    const id =
      typeof window !== "undefined"
        ? new URLSearchParams(location.search).get("id")
        : null;
    getData(id);
  }, [location.search]);

  const [img, setImg] = useState("");
  const [plant, setPlant] = useState(0);
  const [imageVisible, setImageVisible] = useState(false);
  function handleChange(key) {
    setPlant(key);
  }
  function confirm() {
    Modal.confirm({
      title: "Tem certeza que deseja excluir este prédio?",
      okText: "Sim",
      okType: "danger",
      onOk: async () => {
        await api({
          method: "DELETE",
          url: `/predios/${predio.id}`,
          headers: {
            Authorization: `Bearer ${context?.token}`,
          },
        });
        navigate("/");
        message.success("Excluído com sucesso.");
      },
      cancelText: "Não",
    });
  }
  const preview = {
    mask: (
      <div className="flex">
        <ZoomInOutlined className="mr-1 mt-1" />
        <div>Ampliar</div>
      </div>
    ),
  };
  const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    width: 30,
    height: 30,
    cursor: "pointer",
    color: "white",
    backgroundColor: "#D27A34",
  };

  return (
    <Layout
      padding={false}
      loading={loading}
      image={predio?.logo && `${url}${predio?.logo?.url}`}
    >
      <SEO
        keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
        title={predio?.nomePredio}
      />
      <div className="mx-auto w-4/5">
        {context?.user && (
          <>
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-10">
              <Button
                style={{ borderColor: "orange", color: "orange" }}
                danger
                onClick={() =>
                  navigate(`/empreendimentos/novoPredio?id=${predio?.id}`)
                }
              >
                Editar
              </Button>
              <Button type="primary" danger onClick={confirm}>
                Excluir
              </Button>
            </div>
          </>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 my-12 self-center">
          <img className="" src={`${url}${predio?.imagemPrincipal.url}`} />
          <div>
            <ReactMarkdown
              source={predio?.descricao}
              className="grid grid-cols-1 mt-6 gap-12 lg:gap-5 opacity-75"
              // eslint-disable-next-line react/display-name
              renderers={{
                paragraph: (props) => <p name="a" {...props} />,
              }}
            />
            <h3 style={{ textAlign: "center" }}>
              Empreendimento da linha
              {predio?.classe === "BivverADream" ? (
                <>
                  {" "}
                  Bivver Dream{" "}
                  <img
                    style={{ position: "relative", bottom: "8px" }}
                    width="92px"
                    src={dream}
                  />
                </>
              ) : predio?.classe === "BivverBClub" ? (
                <>
                  {" "}
                  Bivver Club{" "}
                  <img
                    style={{ position: "relative", bottom: "8px" }}
                    width="92px"
                    src={club}
                  />
                </>
              ) : (
                <>
                  {" "}
                  Bivver Exclusivity{" "}
                  <img
                    style={{ position: "relative", bottom: "8px" }}
                    width="92px"
                    src={exclusivity}
                  />
                </>
              )}
            </h3>
          </div>
        </div>
        {predio?.caracteristicas?.length > 0 && (
          <React.Fragment>
            <h2 className="my-10 text-center">Características</h2>
            <div
              className={`bivverBlack mb-10 grid grid-cols-1 md:grid-cols-${
                predio?.caracteristicas.length === 1
                  ? "1"
                  : predio?.caracteristicas.length === 2
                  ? "2"
                  : "3"
              } gap-10 lg:gap-48 self-center`}
            >
              {predio?.caracteristicas?.map((c) => (
                <div key={c.id} className="ml-10">
                  <h3 className="mb-8">{c.titulo}</h3>
                  <ReactMarkdown
                    className="grid grid-cols-1 gap-2"
                    renderers={{
                      paragraph: (props) => (
                        <p className="text-sm opacity-75" {...props} />
                      ),
                    }}
                    style={{ fontSize: ".875rem" }}
                  >
                    {c.descricao}
                  </ReactMarkdown>
                </div>
              ))}
            </div>
          </React.Fragment>
        )}
        {predio?.planta?.length > 0 && predio?.estado !== "Entregue" && (
          <React.Fragment>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "32px",
              }}
            >
              <h2
                className="md:w-1/3 mt-16"
                style={{
                  textAlign: "center",
                  paddingBottom: "12px",
                  borderBottomWidth: "1.5px",
                  borderBottomColor: "black",
                }}
              >
                Plantas
              </h2>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
              <Carousel
                renderIndicator={(clickHandler, isSelected) => (
                  <div
                    onClick={clickHandler}
                    style={{
                      height: "0.5rem",
                      width: "0.5rem",
                      borderRadius: "8px",
                      display: "inline-block",
                      margin: "0 8px",
                      backgroundColor: isSelected ? "#D27A34" : "#E0E0E0",
                    }}
                  ></div>
                )}
                infiniteLoop
                dynamicHeight
                selectedItem={plant}
                showThumbs={false}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <div
                      className="rounded-full btn-bivver"
                      onClick={onClickHandler}
                      title={label}
                      style={{
                        ...arrowStyles,
                        alignContent: "center",
                        alignItems: "center",
                        left: 15,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <LeftOutlined />
                      </div>
                    </div>
                  )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                  hasNext && (
                    <div
                      className="rounded-full btn-bivver"
                      onClick={onClickHandler}
                      title={label}
                      style={{
                        ...arrowStyles,
                        alignContent: "center",
                        alignItems: "center",
                        right: 15,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <RightOutlined />
                      </div>
                    </div>
                  )
                }
                showStatus={false}
                stopOnHover
                // onClickItem={(key) => handleClick(key)}
                onChange={(key) => handleChange(key)}
                className="inline"
              >
                {predio?.planta?.map((p) => (
                  <Image
                    preview={preview}
                    className="inline"
                    key={p.id}
                    src={`${url}${p.planta?.url}`}
                  />
                ))}
              </Carousel>
              <div className="ml-10">
                <h3 className="mb-8">{predio?.planta[plant].titulo}</h3>
                <ReactMarkdown
                  className="grid grid-cols-1 gap-2"
                  renderers={{
                    paragraph: (props) => (
                      <p className="text-lg opacity-75" {...props} />
                    ),
                  }}
                  style={{ fontSize: ".875rem" }}
                >
                  {predio?.planta[plant].descricao}
                </ReactMarkdown>
              </div>
            </div>
          </React.Fragment>
        )}

        {predio?.estado == "EmConstrucao" && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "8px",
              }}
            >
              <h2
                className="md:w-1/3 mt-16"
                style={{
                  textAlign: "center",
                  paddingBottom: "8px",
                  borderBottomWidth: "1.5px",
                  borderBottomColor: "black",
                }}
              >
                Acompanhamento da obra
              </h2>
            </div>
            <h5
              className="text-center"
              style={{
                fontFamily: "Source Sans Pro",
                letterSpacing: "0px",
                color: "#000000",
                opacity: 0.54,
              }}
            >
              Data de inicio:{" "}
              {predio?.comecoObra
                ? moment(predio?.comecoObra).format("MM/YYYY")
                : "xx/xxxx"}{" "}
              | Data de término :{" "}
              {predio?.fimObra
                ? moment(predio?.fimObra).format("MM/YYYY")
                : "xx/xxxx"}
            </h5>
            <br />
            {predio?.progresso.map((p) => (
              <Progress
                key={p.id}
                percentage={`${p.porcentagem}`}
                description={`${p.descricao}`}
              />
            ))}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "32px",
              }}
            >
              <h2
                className="md:w-1/3 mt-16"
                style={{
                  textAlign: "center",
                  paddingBottom: "12px",
                  borderBottomWidth: "1.5px",
                  borderBottomColor: "black",
                }}
              >
                Fotos da obra
              </h2>
            </div>
            {predio?.timeline && <Timeline timeline={predio?.timeline} />}
          </>
        )}
        {predio?.galeria?.length > 0 && (
          <div className="mx-auto w-4/5">
            <React.Fragment>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "32px",
                }}
              >
                <h2
                  className="md:w-1/3 mt-16"
                  style={{
                    textAlign: "center",
                    paddingBottom: "12px",
                    borderBottomWidth: "1.5px",
                    borderBottomColor: "black",
                  }}
                >
                  {predio?.estado === "Entregue"
                    ? "Fotos do empreendimento"
                    : "Galeria de imagens"}
                </h2>
              </div>

              <Carousel
                className="mx-auto lg:w-3/4 self-center mt-6"
                showStatus={false}
                centerMode
                centerSlidePercentage="100"
                infiniteLoop
                dynamicHeight
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <div
                      className="rounded-full btn-bivver"
                      onClick={onClickHandler}
                      title={label}
                      style={{
                        ...arrowStyles,
                        alignContent: "center",
                        alignItems: "center",
                        left: 15,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <LeftOutlined />
                      </div>
                    </div>
                  )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                  hasNext && (
                    <div
                      className="rounded-full btn-bivver"
                      onClick={onClickHandler}
                      title={label}
                      style={{
                        ...arrowStyles,
                        alignContent: "center",
                        alignItems: "center",
                        right: 15,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <RightOutlined />
                      </div>
                    </div>
                  )
                }
                renderIndicator={(clickHandler, isSelected) => (
                  <div
                    onClick={clickHandler}
                    style={{
                      height: "0.5rem",
                      width: "0.5rem",
                      borderRadius: "8px",
                      display: "inline-block",
                      margin: "0 8px",
                      backgroundColor: isSelected ? "#D27A34" : "#E0E0E0",
                    }}
                  ></div>
                )}
                stopOnHover
              >
                {predio?.galeria.map((g) => {
                  const ratio = g.height / g.width;
                  return (
                    <Image
                      preview={preview}
                      style={{
                        width: ratio > 1 ? "50%" : ratio > 0.7 ? "65%" : "",
                      }}
                      key={g.id}
                      src={`${url}${g.url}`}
                    />
                  );
                })}
              </Carousel>
            </React.Fragment>
          </div>
        )}
      </div>
      {predio?.endereco && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "32px",
            }}
          >
            <h2
              className="md:w-1/3 mt-16"
              style={{
                textAlign: "center",
                paddingBottom: "12px",
                borderBottomWidth: "1.5px",
                borderBottomColor: "black",
              }}
            >
              Localização
            </h2>
          </div>
          <iframe
            className="w-full border-solid border-2 border-blue-500 mb-16"
            title="SoftinLocationMap"
            height="360"
            src={`https://maps.google.com/maps?q=${encodeURI(
              predio?.endereco
            )}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
            allowFullScreen=""
          />
        </div>
      )}
      {predio?.estado !== "Entregue" && (
        <div className="mx-auto w-4/5">
          {predio?.post && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 my-16 self-center">
              <div className="grid grid-cols-1 gap-5">
                <div
                  style={{
                    display: "flex",
                    marginBottom: "32px",
                  }}
                >
                  <h2
                    style={{
                      paddingBottom: "0px",
                      borderBottomWidth: "1.5px",
                      borderBottomColor: "black",
                    }}
                  >
                    {predio?.post?.titulo}
                  </h2>
                </div>
                <ReactMarkdown
                  renderers={{
                    paragraph: (props) => <p className="text-xl" {...props} />,
                  }}
                >
                  {predio?.post?.conteudo}
                </ReactMarkdown>
              </div>
              <img src={`${url}${predio?.post?.capa?.url}`} />
            </div>
          )}
        </div>
      )}
    </Layout>
  );
}

Predio.propTypes = {
  data: PropTypes.any,
  location: PropTypes.any,
};
